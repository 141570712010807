import React, { useState } from "react";
import OurPriceComponent from "./price/OurPriceComponent";

const LandingPricing = () => {
  const [type, setType] = useState("At-Home");

  return (
    <div>
      <div className="package-price">
        <div className="package-price-content">
          <div className="package-price-content-col-1">
            <div>
              <div className="package-price-content-col-1-heading">
                Save your <span>Time</span> and <span>Money</span>
              </div>
              <div className="package-price-content-col-1-content">
                <div
                  onClick={(e) => setType("At-Home")}
                  className={
                    type === "At-Home"
                      ? "package-price-content-col-1-col-red"
                      : "package-price-content-col-1-col"
                  }
                >
                  Service Charges
                </div>
              </div>
            </div>
          </div>

          <div className="package-price-content-col-2">
            <div className="package-price-heading">
              {type === "At-Home"
                ? "At-Home Service"
                : type === "Road Side"
                ? "RSA Service"
                : "Service"}{" "}
              <span>Price list</span>
            </div>
            <div className="package-price-head-text">
              Wondering how much does it cost to service a superbike? scooter Repair or
              scooty service cost may depend upon the type of service you
              opt for. Refer below to get the estimated labour of Two Wheeler Service.
            </div>
            <div className="package-price-head-category">
              <div
                onClick={(e) => setType("At-Home")}
                className={
                  type === "At-Home"
                    ? "package-price-head-category-col-red"
                    : "package-price-head-category-col"
                }
              >
                Service
              </div>
              {/* <div></div> */}
              <span>
                {type === "At-Home" ? (
                  <i className="fa-solid fa-caret-down"></i>
                ) : (
                  ""
                )}
              </span>
            </div>

            <OurPriceComponent type={type} />
          <span id="check-list"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPricing;
