import React, { useEffect } from "react";
import LandingNavbar from "../../components/user/LandingNavbar";
import LandingNavTop from "../../components/user/LandingNavTop";
import LandingCheckList from "../../components/user/LandingCheckList";
import LandingHeader from "../../components/user/LandingHeader";
import LandingServices from "../../components/user/LandingServices";
import LandingPricing from "../../components/user/LandingPricing";
import LandingOurLocation from "../../components/user/LandingOurLocation";
import LandingBenefit from "../../components/user/LandingBenefit";
import LandingTrustedBrand from "../../components/user/LandingTrustedBrand";
import LandingBikeBrand from "../../components/user/LandingBikeBrand";
import LandingFooter from "../../components/user/LandingFooter";
import ButtonComponent from "../../components/user/ButtonComponent";
import LandingTestimonial from "../../components/user/LandingTestimonial";
import { Helmet } from "react-helmet";
import ContactFixButton from "../../components/user/ContactFixButton";

const LandingPage = ({ location }) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          name="keywords"
          content="
scooter repair at home,
bike repair,
bike mechanic,
2 wheeler service at home,
online bike service,
bike service delhi,
bike service at home,
two wheeler service at home,
home bike repair service near me,
bike repair home service near me,
two wheeler repair home service,
bike mechanic at home,
home bike service near me,
scooty service,
bike mechanic near me,
bike service near me,
two wheeler service,
scooty repair at home,
scooty servicing at home,
bike repair near me,
bike repair service,
bike repair at home,
scooter mechanic near me,
bike repair on call,
bike repair at home near me,
two wheeler mechanic home service,
bike service at home near me,
home two wheeler service,
2 wheeler home service,
bike service,
scooty mechanic near me,
nearby bike mechanic,
bike service at home gurgaon,
online bike service at home,
two wheeler service at doorstep,
2 wheeler repair at home,
bike mechanic home service near me,
two wheeler repair at home,
two wheeler home service near me
      
      "
        />

        <title>Bike Repair, Your Location, Your Convenience</title>
        <meta
          name="description"
          content="
        Experience hassle-free bike maintenance and repairs brought right to your doorstep with our mobile service. Get your bike serviced and repaired hassle-free at your doorstep or visit our location in Noida, Greater Noida, Ghaziabad, Delhi, Faridabad and Gurgaon.
        "
        />
      </Helmet>

      <div className="landing-page">
        <div id="book-form"></div>
        <div className="landing-sticky-nav">
          <LandingNavTop />
          <LandingNavbar />
        </div>

        <LandingHeader location={location} />

        <LandingServices location={location} />

        <div className="bg-black">
          <LandingPricing location={location} />
        </div>

        <LandingCheckList location={location} />

        <div className="bg-black">
          <LandingBenefit location={location} />
        </div>

        <LandingTestimonial />
        <LandingOurLocation />

        <div className="bg-black">
          <LandingTrustedBrand />
        </div>

        <LandingBikeBrand />
        <LandingFooter />

        <ContactFixButton />
      </div>
    </div>
  );
};

export default LandingPage;
