import React from "react";
import icon1 from "../../images/icon/warranty.png.webp"
import icon2 from "../../images/icon/deal.png.webp"
import icon3 from "../../images/icon/transparency.png.webp"
import icon4 from "../../images/icon/expert.png.webp"

const LandingBenefit = ({bike, model, location}) => {
  return (
    <div>
      <div className="our-benefits">
        <div className="our-benefits-heading">
          Why Chooses <span> Garage On Call?</span>
        </div>
      {/* <div className="heading-underline"><hr /></div> */}

        <div className="our-benefits-content">
          <div className="our-benefits-col-1">
            <div className="our-benefits-col-1-text">
            Get Rs.100 off on first service</div>
            <div className="our-benefits-col-1-heading">

            {
            bike?<><span>{bike}</span> Bike Service Warranty</> 
            :model?<><span>{model}</span> Service Warranty</> 
            :location?<><span>{location}</span> Location Service Warranty</> 
            :
            <><span>Garage On Call</span> Service Warranty</> 
            }
              
            </div>
            <div className="our-benefits-col-1-text">
              Garage On Call provides excellent bike repair services at home for
              motorcycles of all models and brands including 
              Harley Davidson, Ducati, Benelli, Triumph, Indian, BMW, Aprilia, Yezdi, Husqvarna, etc.
            </div>
          </div>
          
          <div className="our-benefits-col-2">

            <div className="our-benefits-col-2-col">
              <div className="our-benefits-col-2-col-icon"><img src={icon1} alt="icon" title="Garage On Call - Bike Service Guarantee" /></div>
              <div className="our-benefits-col-2-col-content">
                <div className="our-benefits-col-2-col-heading">
                  10 days Free Service Guarantee
                </div>
                <div className="our-benefits-col-2-col-text">
                  10 days Unconditional Warranty
                </div>
              </div>
            </div>

            <div className="our-benefits-col-2-col">
              <div className="our-benefits-col-2-col-icon"><img src={icon2} alt="icon" title="Garage On Call - Free Bike Pickup & Drop Facility" /></div>
              <div className="our-benefits-col-2-col-content">
                <div className="our-benefits-col-2-col-heading">
                  Free Pickup & Drop service
                </div>
                <div className="our-benefits-col-2-col-text">
                  Free Pick & Drop Available
                </div>
              </div>
            </div>

            <div className="our-benefits-col-2-col">
              <div className="our-benefits-col-2-col-icon"><img src={icon3} alt="icon" title="Garage On Call - Transparent price" /></div>
              <div className="our-benefits-col-2-col-content">
                <div className="our-benefits-col-2-col-heading">
                  Transparent & Competitive Pricing
                </div>
                <div className="our-benefits-col-2-col-text">
                  Save upto 40% on your bike service
                </div>
              </div>
            </div>

            <div className="our-benefits-col-2-col">
              <div className="our-benefits-col-2-col-icon"><img src={icon4} alt="icon" title="Garage On Call - Trained Mechanics" /></div>
              <div className="our-benefits-col-2-col-content">
                <div className="our-benefits-col-2-col-heading">
                  Trained & Experts Mechanics
                </div>
                <div className="our-benefits-col-2-col-text">
                  Only certified two-wheeler mechanics
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div id="testimonials" className="landing-page-button">

<a href="#book-form">Request Call Back</a>

</div>
      </div>
        
        
    </div>
  );
};

export default LandingBenefit;
