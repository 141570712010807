import React, { useRef } from "react";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const LandingNavbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("landing-nav-open");
  };

  return (
    <div>
      <div className="landing-nav" >
        <div className="landing-nav-main">
          <div className="landing-nav-logo">
          <div className="landing-nav-logo-image">
            <img src={logo} />
          </div>
          </div>

          <div className="landing-nav-menu" ref={navRef}>
            <div
              className="landing-nav-btn landing-nav-close-btn"
              onClick={showNavbar}
            >
              <i className="fa-solid fa-circle-xmark"></i>
            </div>


            <a onClick={showNavbar} href="#our-service">Our Services</a>
            <a onClick={showNavbar} href="#price-list">Packages</a>
            <a onClick={showNavbar} href="#check-list">Check List</a>
            <a onClick={showNavbar} href="#why-us">Why Us</a>
            <a onClick={showNavbar} href="#testimonials">Testimonials</a>
          </div>

          <div
            className="landing-nav-btn landing-nav-menu-btn"
            onClick={showNavbar}
          >
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </div>

    </div>
  );
};

export default LandingNavbar;
