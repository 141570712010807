import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import warrantyIcon from "../../images/icon/warranty.svg";
import expertsIcon from "../../images/icon/experts.svg";
import bestcustomerIcon from "../../images/icon/bestcustomer.svg";
import NumberForm from "../forms/NumberForm";

const LandingHeader = ({ location }) => {

 
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // form animation :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [isVisible, setIsVisible] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);  // Text is visible, trigger animation
      } else {
        setIsVisible(false); // Text is not visible, reset animation
      }
    }, { threshold: 0.5 });

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);


// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  

  return (
    <div>
      <div className="area-location-header">
        <div className="landing-header-content">
          <div className="area-location-header-col-1">
            <div className="area-location-header-col-1-headings">
              <h1 className="area-location-header-col-1-heading-1">
                <>
                  Bike Service & Repair at Your Doorstep in
                  <div className="text-capitalize">&nbsp;{location}&nbsp;</div>
                </>
              </h1>
              <div className="area-location-header-col-1-heading-2">
                Book Expert Mechanic to Fix your <br />
                Scooty at Your Home
              </div>
            </div>

            <div className="area-location-header-col-1-buttons">
              <div className="area-location-header-col-1-button">
                <Link
                  title="doorstep bike service at home"
                  target="_blank"
                  to="tel:+919658124124"
                >
                  <i className="fa-solid fa-phone-volume"></i> &nbsp; 
                  {/* Call For Offers & Booking */}
                  +91 9658-124-124
                </Link>
              </div>
            </div>

            <div className="area-location-header-col-1-increment-cols">
              <div className="area-location-header-col-1-increment-col">
                <div className="area-location-header-col-1-increment-col-number">
                  <CountUp end={4} duration="2" enableScrollSpy />.
                  <CountUp end={7} duration="7" enableScrollSpy />/ 5
                </div>
                <div className="area-location-header-col-1-increment-col-text">
                  Based on 1,00,000+ <br />
                  Reviews
                </div>
              </div>
              <div className="area-location-header-col-1-increment-col">
                <div className="area-location-header-col-1-increment-col-number">
                  <CountUp end={100000} duration="3" enableScrollSpy /> +
                </div>
                <div id="our-service" className="area-location-header-col-1-increment-col-text">
                  Happy <br />
                  Customers
                </div>
              </div>
            </div>
          </div>

          <div ref={formRef} id="location-form" className={`area-location-header-col-2 ${isVisible ? 'landing-header-form-animate' : ''}`}>

              <NumberForm heading={`Two Wheeler Services & Repair at Home in ${location}`}/>
            
            
            {/* <div id="location-form" className="area-location-header-col-2">
              <div className="area-location-header-col-2-content">
                <div className="area-location-header-col-2-headings">
                  <div className="area-location-header-col-2-heading-1">
                    <div className="text-capitalize">
                      Bike Service in {location}
                    </div>
                  </div>
                  <div className="area-location-header-col-2-heading-2">
                    Now Get Your Bike Service at Home and Save Your Time and
                    Money!
                  </div>

                  <div className="area-location-header-col-2-form">
                    <form>
                      <div className="sb-c3-1-col"></div>

                      <h3>Enter your mobile number</h3>

                      <input
                        type="number"
                        placeholder="phone number*"
                        required
                        name="phone"
                        onChange={handleChange}
                      />

                      <button>Book Now</button>
                    </form>

                    <div className="area-location-header-col-2-end">
                      <div className="area-location-header-col-2-end-col">
                        <div className="area-location-header-col-2-end-icon">
                          <img src={warrantyIcon} alt="icon" title="icon" />
                        </div>
                        <div className="area-location-header-col-2-end-text">
                          10 Days Service Warranty
                        </div>
                      </div>

                      <div className="area-location-header-col-2-end-col">
                        <div className="area-location-header-col-2-end-icon">
                          <img src={expertsIcon} alt="icon" title="icon" />
                        </div>
                        <div className="area-location-header-col-2-end-text">
                          Expert <br />
                          Mechanics
                        </div>
                      </div>

                      <div className="area-location-header-col-2-end-col">
                        <div className="area-location-header-col-2-end-icon">
                          <img src={bestcustomerIcon} alt="icon" title="icon" />
                        </div>
                        <div className="area-location-header-col-2-end-text">
                          Best Customer
                          <br /> Support{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
