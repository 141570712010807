import React from "react";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const LandingFooter = () => {
  return (
    <div>
      <div className="landing-footer">
        <div className="landing-footer-main">
          <div className="landing-footer-col-1">
            <div className="landing-footer-logo">
              <img src={logo} />
            </div>
            <div className="landing-footer-icons">
              <div>
                <i className="fa-brands fa-facebook-f" />
              </div>
              <div>
                <i className="fa-brands fa-x-twitter" />
              </div>
              <div>
                <i className="fa-brands fa-instagram" />
              </div>
              <div>
                <i className="fa-brands fa-linkedin-in" />
              </div>
              <div>
                <i className="fa-brands fa-youtube" />
              </div>
            </div>
          </div>
          <div className="landing-footer-col-2">

            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-heading">QUICK LINKS</div>
              <div className="landing-footer-col-2-points">
                <a href="#our-service">Our Services</a>
                <a href="#price-list">Packages</a>
                <a href="#check-list">Check List</a>
                <a href="#why-us">Why Us</a>
                <Link to="/privacy-policy-2" target="_blank">Privacy Policy</Link>
              </div>
            </div>
            
            

            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-heading">CORPORATE OFFICE</div>
              <div className="landing-footer-col-2-points">
                <div><i class="fa-solid fa-location-arrow"></i> 520, 5th Floor, Ocean Complex, <br />Sector-18, Noida - 201301</div>
                <div><i class="fa-solid fa-mobile-screen-button"></i> <a href="tel:01204225828" target="blank">0120 4225828</a></div>
                <div><i class="fa-solid fa-envelope"></i> query@garageoncall.com</div>
                <div><i class="fa-brands fa-whatsapp"></i> <a href="https://wa.me/+919658124124?text=Hello ! I'm Interested in Doorstep Bike Service. Please contact me." target="blank">+91 9658-124-124</a></div>
                <div></div>
                <div></div>
              </div>
            </div>

          </div>
        </div>

        <div className='landing-footer-cc'>Copyright <i className="fa-regular fa-copyright"></i> 2019, Garage on Call. All Rights Reserved.</div>

        
      </div>
    </div>
  );
};

export default LandingFooter;
