import React from "react";
import { Link, useLocation } from "react-router-dom";

const ContactFixButton = () => {

  const location = useLocation();
  console.log(location.pathname); // Outputs the current pathname

  
  return (
    <div>

      {
      location.pathname === "/thank-you" ?
      ""
      :
      <div className="contact-button">
        
      <div className="call-button">
      <Link  className="call-button-icon"  target="_blank" to="tel:+919658124124">
              <i class="fa-solid fa-phone-volume"></i>
          </Link>
    </div>


    <div className="whatsapp-button">
      <Link  className="whatsapp-button-icon" target="_blank" to="https://wa.me/+919658124124?text=Hello ! I'm Interested in Doorstep Bike Service. Please contact me.">
        <i class="fa-brands fa-whatsapp"></i>
      </Link>
    </div>
        
      </div>
}


    </div>
  );
};

export default ContactFixButton;
