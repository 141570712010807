import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon1 from '../../images/icon/Periodic-Service.png'
import icon3 from '../../images/icon/Bike-Insurance.png'
import icon4 from '../../images/icon/Doorstep-Bike-Services.png'
import icon5 from '../../images/icon/Bike-Wheel-Care.png'
import icon8 from '../../images/icon/Bike-Battery.png'
import icon11 from '../../images/icon/Bike-Engine-Repair.png'

const   LandingServices = ({location}) => {

  
  return (
      <div className="">

      <div className="category-component">
          <div className="category-component-heading">
           
            <>Services at Home in <span>{location} </span></>

          </div>
          <div className="category-component-head-text">
          Get professional periodic superbike service at Home. Get bullet engine repair, doorstep battery replacement, wheel, and tyre care services. Also, you can buy bike and scooty insurance and much more done at the best prices.
          </div>
      
      <div className="category-component-content">

        <div title="Doorstep two wheeler routine Service" className="category-component-col">
          <div className="category-component-col-img"><img src={icon1} alt="icon" title="Bike routine Service at home" /></div>
          <div className="category-component-col-text">Routine Service</div>
        </div>


        <div title="Online Two wheeler Insurance" className="category-component-col">
          <div className="category-component-col-img"><img src={icon3} alt="icon" title="Online Bike Insurance Service" /></div>
          <div className="category-component-col-text">Repair Work</div>
        </div>


        <div to="/bike/routine-service" className="category-component-col">
          <div className="category-component-col-img"><img src={icon4} alt="icon" title="Bike Service at home" /></div>
          <div className="category-component-col-text">Doorstep Service</div>
        </div>


        <div title="Doorstep two wheeler Wheel Care Service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon5} alt="icon" title="Bike Wheel Care Service at home" /></div>
          <div className="category-component-col-text">Wheel Care</div>
        </div>



        <div title="Doorstep two wheeler battery service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon8} alt="icon" title="Bike Batteries Service at home" /></div>
          <div className="category-component-col-text">Batteries</div>
        </div>


        <div title="Doorstep two wheeler Engine Repair" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon11} alt="icon" title="Bike Engine Repair at home" /></div>
          <div className="category-component-col-text">Engine Repair</div>
        </div>


      </div>

      <div id="price-list" className="landing-page-button">

<a href="#book-form">Request Call Back</a>

</div>
      
    </div>
    </div>
  );
};

export default  LandingServices;
